import qs from "qs";

export function getDecodedUserPermissionsInvitationDataFromQueryString(rawQueryString) {
    let qsHash = (qs.parse(rawQueryString, { ignoreQueryPrefix: true }).hash || '');
    let decodedString = Buffer.from(qsHash, 'base64').toString('utf-8');
    let isDynamicRole = decodedString.startsWith('dynamicrole-');
    const [hash, encodedEmail, expiryTimestamp] = decodedString.split(/\//);

    return {
        originalHash: qsHash,
        code: hash,
        email: expiryTimestamp ? decodeURIComponent(encodedEmail) : encodedEmail,   // if expiryTimestamp is not present, it means the previous version of invite urls, meaning the email is not encoded
        isDynamicRole,
        expiryTimestamp,
    }
}

export const ERROR_TYPES = {
    EMAIL_MISMATCH: 'EMAIL_MISMATCH',
    ROLE_MISMATCH: 'ROLE_MISMATCH',
    GENERIC_ACCEPT_ERROR: 'GENERIC_ACCEPT_ERROR',
    GENERIC_DENY_ERROR: 'GENERIC_DENY_ERROR',
};
