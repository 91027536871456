import actions from './actions';

const initialState = {
  originalHash: null,
  code: null,
  email: null,
  isDynamicRole: false,
  expiryTimestamp: 0,
  isAccepting: false,
  isAcceptedUserDelegationInvitation: false,
  error: false,
  isConversionRequired: false,
}

export default (state = initialState, action ) => {
  switch (action.type) {

  case actions.SET_INVITATION_DATA:
    return {
      ...state,
      originalHash: action.originalHash,
      code: action.code,
      email: action.email,
      isDynamicRole: action.isDynamicRole,
      expiryTimestamp: action.expiryTimestamp,
    }

  case actions.SET_ACCEPTING_INVITATION:
    return {
      ...state,
      isAccepting: action.payload,
    }

  case actions.SET_ACCEPTED_USER_DELEGATION_INVITATION:
    return {
      ...state,
      isAccepting: false,
      isAcceptedUserDelegationInvitation: true,
    }

  case actions.SET_ARTIST_CONVERSION_REQUIRED:
    return {
      ...state,
      isAccepting: false,
      isConversionRequired: true,
    }

    case actions.SET_USER_DELEGATION_INVITATION_ERROR:
    return {
      ...state,
      isAccepting: false,
      error: action.error,
      errorData: action.errorData,
    }

  default:
    return state
  }
}
