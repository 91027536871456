const PAGE_TITLE_COMMON = " | " + process.env.BRAND_NAME;

export default {
  //  Page titles
  PAGE_TITLE_COMMON,
  'PAGE_TITLE_/login': "Login" + PAGE_TITLE_COMMON,
  'PAGE_TITLE_/signup': "Sign Up" + PAGE_TITLE_COMMON,
  'PAGE_TITLE_/forgot': "Reset Password" + PAGE_TITLE_COMMON,
  'PAGE_TITLE_/onboarding': "Complete Profile" + PAGE_TITLE_COMMON,

  SLOGAN: 'All-in-one music marketing platform',
  //  Auth parts
  LOGIN_TITLE: 'Log In to get started',
  SUCCESS_TITLE: 'Success!',
  RESET_TITLE: 'Reset Password',
  SIGNUP_TITLE: 'Sign Up to Proceed',

  SOUNDCLOUD_LOGIN_TITLE: 'Don’t I know you from somewhere?',
  SOUNDCLOUD_LOGIN_DESCRIPTION: 'We found your email already tied to a Feature.fm account. Prove your ownership and we’ll take you to where you can unlock exclusive offers.',

  ACCEPT_INVITATION_ERROR_TITLE: 'Error accepting invitation',
  ACCEPT_INVITATION_ERROR_SUBTITLE: 'An internal error occurred while accepting invitation. Please verify the invitation link is valid.',
  DENY_INVITATION_ERROR_SUBTITLE: 'An internal error occurred while denying invitation. Please verify the invitation link is valid.',
  EXPIRED_INVITATION_ERROR_SUBTITLE: 'Invitation link has expired. Please ask asset owner to send a new invite.',

  ACCEPT_INVITATION_ERROR_SUBTITLE_EMAIL_MISMATCH: 'This artist management invite is only valid for {invitation}. Please check that you are signed into this account.',
  ACCEPT_INVITATION_ERROR_SUBTITLE_ROLE_MISMATCH: 'Accepting this invitation requires you to convert your account to a "Business Account" that can manage more than one artist. Please <cu>contact support</cu> to complete this action.',

  SEND_INVITATION_MODAL_TITLE: 'To proceed you need to accept the invite that was sent to your email first',
  SEND_INVITATION_MODAL_SUBTITLE_SUCCESS: 'Invitation sent successfully',
  SEND_INVITATION_MODAL_SUBTITLE_ERROR: 'An internal error occurred while sending invitation. Please retry the operation or contact support.',
  CONTINUE_TO_SITE_IF_INVITATION_ACCEPTED: 'Continue to site if you have already accepted the invitation',
  SEND_INVITE: 'Send Invite',
  RESEND_INVITE: 'Resend Invite',

  EMAIL_VERIFIED_TITLE: 'Email address successfully verified',
  EMAIL_VERIFIED_SUBTITLE: 'You now have permissions to perform actions on feature-links and ad-campaigns',
  VERIFY_EMAIL_ERROR_TITLE: 'Error verifying email address',
  VERIFY_EMAIL_ERROR_SUBTITLE: 'An internal error occurred while verifying email address. Please verify the confirmation link is valid.',
  ERROR_MISMATCH_VERIFICATION_EMAIL: 'This email verification link is invalid. Please check that you are signed into the correct account.',

  RETRY_OPERATION: 'Retry Operation',

  USER_DELEGATION_INVITATION_ACCEPTED_TITLE: 'Invitation accepted successfully',
  USER_DELEGATION_INVITATION_ACCEPTED_SUBTITLE: 'You now have permissions to perform actions on behalf of the inviting organization',
  CONTINUE_TO_SITE: 'Continue to site',

  CONVERT_ROLE_TITLE: 'Convert Account to Manage Multiple Artists',
  CONVERT_ROLE_SUBTITLE: 'Accepting this invitation requires you to convert your account to a "Business Account" that can manage more than one artist. {br}{br} <b>This means:</b> You can manage the artist you have been invited to, but will no longer be able to create Pre-Saves or Smart Links in your own account unless you subscribe to a Business Plan. You will still be be able to edit and use free Pre-Saves and Smart Links you have already created.',
  CONVERT_ROLE_ACCEPT: 'Accept invitation and convert to a Business Account',
  CONVERT_ROLE_DECLINE: 'Decline and keep Artist Account',

  RESET_SUBTITLE: 'Please enter your email, and we\'ll send\nyou a link to reset your password',

  FORGOT_LINK: 'Forgot password?',
  FORGOT_BACK: 'Back to Login',
  FORGOT_BACK_LANDING: 'Back to Feature.fm',
  LOGIN_LINK: 'Already have an account? {link}',
  SIGNUP_LINK: 'Don’t have an account? {link}',

  SIGNUP: 'Sign up',
  LOGIN: 'Log in',

  LOGIN_FACEBOOK_BUTTON: 'Log In with facebook',
  SIGNUP_FACEBOOK_BUTTON: 'Sign Up with facebook',
  LOGIN_SOUNDCLOUD_BUTTON: 'Log In with soundcloud',

  RESET_SUCCESS: 'We sent an email to {email}\nwith a link to reset your password',
  SIGNUP_SUCCESS: '{email}\nPlease wait while redirecting to Feature.fm',

  FACEBOOK_DISCLAIMER: 'We will never post anything on your Facebook\nwithout your permission',
  TOS_DISCLAIMER: 'By clicking sign up, you agree to our\n<tos>Terms of Service</tos> and <pp>Privacy Policy</pp>.',

  SIGNUP_ARTIST: 'I\'m an artist',
  SIGNUP_PROMOTER: 'I represent artists',

  //  Forms
  EMAIL_PLACEHOLDER: 'Your Email',
  PASSWORD_PLACEHOLDER: 'Password',
  FIRSTNAME_PLACEHOLDER: 'First Name',
  LASTNAME_PLACEHOLDER: 'Last Name',
  COMPANYNAME_PLACEHOLDER: 'Company Name',
  OR_SEPARATOR: 'Or',

  ARTIST_PLACEHOLDER: 'Search Artist',

  NAME_LABEL: 'Name',
  NAME_PLACEHOLDER: 'John Doe',

  AVATAR_LABEL: 'Avatar Image',
  AVATAR_DROPZONE_LABEL: 'Upload Image',
  AVATAR_DROPZONE_GUIDELINES: '640x640 pixels\nJPG or PNG',

  ACCOUNT_IMAGE_LABEL: 'Account Image',
  ACCOUNT_TYPE_LABEL: 'Organization Type',
  ACCOUNT_TYPE_PLACEHOLDER: 'Select Organization Type',

  LOCATION_LABEL: 'Location',
  LOCATION_PLACEHOLDER: 'United States',

  CURRENCY_LABEL: 'Currency',
  CURRENCY_PLACEHOLDER: '$ USD',

  WEBSITE_LABEL: 'Website',
  WEBSITE_PLACEHOLDER: 'http://mysite.com',

  SPOTIFY_LABEL: 'Spotify',
  INSTAGRAM_LABEL: 'Instagram',
  YOUTUBE_LABEL: 'YouTube',
  TIKTOK_LABEL: 'TikTok',
  FACEBOOK_LABEL: 'Facebook',
  SOUNDCLOUD_LABEL: 'Soundcloud',
  TWITTER_LABEL: 'Twitter',

  URL_PLACEHOLDER: 'Enter your URL…',

  //  Onboarding
  ONBOARDING_CONTINUE: 'Continue',
  ONBOARDING_SAVE: 'Save and Get Started',
  ONBOARDING_MANUAL_TITLE: 'Hi {user}, please {action} your artist profile',
  ONBOARDING_LABEL_TITLE: 'Hi {user}, please {action} your profile',
  ONBOARDING_QUICK_TITLE: 'Add an Artist',
  ONBOARDING_QUICK_SUBTITLE: 'Search artist by name or Enter your Spotify/Deezer artist page link and we\'ll create the profile for you automatically.',
  ONBOARDING_MANUAL_LABEL: 'Add Artist Manually',

  //  Image Dropzone
  IMAGE_DROPZONE_DEFAULT_LABEL: 'Upload Image',
  IMAGE_DROPZONE_DEFAULT_ACTIVE_LABEL: 'Drop file here',
  IMAGE_DROPZONE_DEFAULT_GUIDELINE: '640x640 pixels\nJPG or PNG',

  //  Errors
  ERROR_NETWORK: "Network Error",
  ERROR_UNKNOWN: "Unknown Error",
  ERROR_SERVER: "Internal Server Error",
  SMARTLINK_RESOLVER_404: "No matching artists found",

  //  Misc
  CONTINUE: 'Continue',
  CANCEL: 'Cancel',
  SELECT: 'Select',
  BACK: 'Back',
  SEARCH: 'Search'
};
