import {
  ERROR_TYPES,
  getDecodedUserPermissionsInvitationDataFromQueryString
} from "@featurefm/common/helpers/invitation";

import {
  all,
  takeEvery,
  put,
  fork,
  select, call
} from "redux-saga/effects";

import actions from "./actions";
import appActions from "../app/actions";
import InvitationService from "@featurefm/common/services/invitation";
import {FFM_CONSOLE_NEXT_REDIRECT_PATH, FFM_CONSOLE_NEXT_WEB_URL} from "@featurefm/common/config/env";
/**
 * Returns react-router location
 *
 * @param {object} state - redux state
 */
export const getLocation = state => {
  return state.router.location;
};

export function* getInvitationData() {
  yield takeEvery(actions.GET_INVITATION_DATA, function*() {
    const currentLocation = yield select(getLocation);
    if (currentLocation.search) {
      const {originalHash, code, email, expiryTimestamp, isDynamicRole} = getDecodedUserPermissionsInvitationDataFromQueryString(currentLocation.search);
      yield put({
        type: actions.SET_INVITATION_DATA,
        originalHash,
        code,
        email,
        expiryTimestamp,
        isDynamicRole,
      });
    }
  });
}

const isUserSubscriptionPlanFree = (plan) => {
  return !plan || plan.startsWith('free');
}

export function* acceptInvitation() {
  yield takeEvery(actions.ACCEPT_INVITATION, function*({ forceConvert }) {

    const invitationCode = yield select((state) => state.AcceptInvitation.code);

    const isCurrentlyAccepting = yield select((state) => state.AcceptInvitation.isAccepting);
    // found invitation code but already in accepting process, skipping
    if (isCurrentlyAccepting) return;

    yield put({
      type: actions.SET_ACCEPTING_INVITATION,
      payload: true,
    });

    try {
      const invitationTypeResponse = yield call(InvitationService.accept, invitationCode, forceConvert);
      if (!invitationTypeResponse || invitationTypeResponse.error || !invitationTypeResponse.data || !invitationTypeResponse.data.type) {

        const errorData = ((invitationTypeResponse || {}).error || {});

        const error = new Error(errorData.message || 'internal error');
        error.code = errorData.status || 500;
        throw error;
      }

      const invitationType = ((invitationTypeResponse || {}).data || {}).type;
      const storeDest = yield select((state) => state.App.dest);

      if (invitationType == 'user_delegation') {
        const { dest, impersonate } = ((invitationTypeResponse || {}).data || {}).data || {};

        if (dest) {
          yield put({
            type: appActions.SET_DEST,
            dest,
          })
        }

        if (impersonate) {
          yield put({
            type: appActions.SET_IMPERSONATE,
            impersonate,
          })
        }

        yield put({
          type: actions.SET_ACCEPTED_USER_DELEGATION_INVITATION,
        });
      }
      else {
        //  Redirect to the main app
        yield put({
          type: appActions.HANDLE_REDIRECT_TO_FFM,
          url: storeDest || (FFM_CONSOLE_NEXT_WEB_URL + FFM_CONSOLE_NEXT_REDIRECT_PATH),
        });
      }
    }
    catch(err) {
      if (err.code == 409) {  // Conflict, MISMATCHING_INVITATION_EMAIL
        yield put({
          type: actions.SET_USER_DELEGATION_INVITATION_ERROR,
          error: ERROR_TYPES.EMAIL_MISMATCH,
          errorData: JSON.parse(err.message),
        });
      }
      else if (err.code == 422) { // Unprocessable, MISMATCHING_INVITED_USER_ROLE
        // for non-free accounts, show error
        const userPlan = yield select((state) => ((state.Auth.user || {}).plan));
        if (!isUserSubscriptionPlanFree(userPlan)) {
          yield put({
            type: actions.SET_USER_DELEGATION_INVITATION_ERROR,
            error: ERROR_TYPES.ROLE_MISMATCH,
            errorData: JSON.parse(err.message),
          });
        }
        // otherwise, for free accounts, show confirmation message
        else {
          yield put({
            type: actions.SET_ARTIST_CONVERSION_REQUIRED,
          });
        }
      }
      else {
        yield put({
          type: actions.SET_USER_DELEGATION_INVITATION_ERROR,
          error: ERROR_TYPES.GENERIC_ACCEPT_ERROR,
        });
      }
    }
  });
}

export function* denyInvitation() {
  yield takeEvery(actions.DENY_INVITATION, function*() {

    const invitationCode = yield select((state) => state.AcceptInvitation.code);

    const isCurrentlyAccepting = yield select((state) => state.AcceptInvitation.isAccepting);
    // found invitation code but already in accepting process, skipping
    if (isCurrentlyAccepting) return;

    yield put({
      type: actions.SET_ACCEPTING_INVITATION,
      payload: true,
    });

    try {
      const invitationTypeResponse = yield call(InvitationService.deny, invitationCode);
      if (!invitationTypeResponse || invitationTypeResponse.error || !invitationTypeResponse.status || invitationTypeResponse.status !== 'success') {
        const errorData = ((invitationTypeResponse || {}).error || {});

        const error = new Error(errorData.message || 'internal error');
        error.code = errorData.status || 500;
        throw error;
      }

      //  Redirect to the main app
      window.location.href = FFM_CONSOLE_NEXT_WEB_URL + FFM_CONSOLE_NEXT_REDIRECT_PATH;
    }
    catch(err) {
      yield put({
        type: actions.SET_USER_DELEGATION_INVITATION_ERROR,
        error: ERROR_TYPES.GENERIC_DENY_ERROR,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getInvitationData),
    fork(acceptInvitation),
    fork(denyInvitation),
  ]);
}
